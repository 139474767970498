import $ from 'jquery';

export default function() {

  var exports = new Object();

  // Helpers responsive
  exports.BREAKPOINTS = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  };

  exports.isSM = function () {
    return $(window).width() < exports.BREAKPOINTS.sm;
  };

  exports.isMD = function () {
    return $(window).width() >= exports.BREAKPOINTS.sm && $(window).width() < exports.BREAKPOINTS.md;
  };

  exports.isLG = function () {
    return $(window).width() >= exports.BREAKPOINTS.md && $(window).width() < exports.BREAKPOINTS.lg;
  };

  exports.isXL = function () {
    return $(window).width() >= exports.BREAKPOINTS.lg && $(window).width() < exports.BREAKPOINTS.xl;
  };

  exports.isXXL = function () {
    return $(window).width() >= exports.BREAKPOINTS.xl;
  };

  exports.isMobile = function () {
    return $(window).width() < exports.BREAKPOINTS.md;
  };

  exports.isDesktop = function () {
    return $(window).width() >= exports.BREAKPOINTS.md;
  };

  return exports;
};
