import '../scss/style.scss';

// require jQuery normally
const $ = require('jquery');

import 'bootstrap';
import breakpoints from './breakpoints.js';
import browserUpdate from 'browser-update';
// create global $ and jQuery variables
global.$ = global.jQuery = $;

window.breakpoints = breakpoints();
browserUpdate({
  required: {
    e:-2,
    f:-2,
    o:-2,
    s:2,
    c:-2
  },
  insecure:true,
  reminder: 0
})
